body {
  width: 100vw;
  height: 100vh;
  background: teal;
}

#root {
  width: 100%;
  height: 100%;
}

@media (max-width: 960px) {
  #root {
    transform: scale(0.9);
  }
}

@media (max-width: 640px) {
  #root {
    transform: scale(0.7);
  }
}
